import React from 'react';
import LocationDataForm from '../form/LocationDataFormComponent';
import { Box, Stack, Text, Divider, StatGroup, Stat, StatLabel, StatNumber, StatHelpText, Flex, Grid, TagLeftIcon, Tag, TagLabel } from '@chakra-ui/react';
import { OutlineButton } from '../form/Button';
import { FaExclamationCircle } from '@react-icons/all-files/fa/FaExclamationCircle';


// const locationDataFields = [
//   {
//     label: 'Business',
//     name: 'business',
//   },
//   {
//     label: 'Address 1',
//     name: 'address1',
//   },
//   {
//     label: 'Address 2',
//     name: 'address2',
//   },
//   {
//     label: 'City',
//     name: 'city',
//   },
//   {
//     label: 'State',
//     name: 'state',
//   },
//   {
//     label: 'Zip',
//     name: 'zip',
//   },
//   {
//     label: 'Lot Number',
//     name: 'lotNumber',
//   },
//   {
//     label: 'Floors Above',
//     name: 'storey',
//   },
//   {
//     label: 'Floors Below',
//     name: 'storeyBelow',
//   },
//   {
//     label: 'Roof Area',
//     name: 'roofArea',
//   },
//   {
//     label: 'Required Flow',
//     name: 'requiredFlow',
//   },
//   {
//     label: 'Risk Assessment Score',
//     name: 'riskAssessmentScore',
//   }
// ];

export const AddressStack = (props) => {

  const AddressLine = p => (
    <Text fontWeight="600" lineHeight="1.25rem">{p.children}</Text>
  );
  return (<Stack className="address" spacing="0">
    {props.address1 && <AddressLine>{props.address1}</AddressLine>}
    {props.address2 && <AddressLine>{props.address2}</AddressLine>}
    {props.city && <AddressLine>{props.city}, {props.state} {props.zip}</AddressLine>}
  </Stack>
  );
};

const LocationData = (props) => {

  return (
    <Box>
      {props.location && props.buildingInMove && (
        <Box p={4}>
          <strong>To move:</strong> Drag the selected location to a new place.
        </Box>
      )}
      {props.location && !props.isPrePlanningMode && (
        <LocationInformation location={props.location} {...props} />
      )}
      {!props.location && !props.isPrePlanningMode && (
        <Text size="sm">No location selected.</Text>
      )}
      {props.locationDataForm && props.locationDataForm.action && props.isPrePlanningMode && (
        <div className="preplanning-content">
          {props.isGettingPreplan ? (
            <div className="processing">
              <img src="/images/processing.gif" alt="" />
            </div>
          ) : (
            <div>
              {props.isPolygonDrawn ? (
                <LocationDataForm
                  locationDataForm={props.locationDataForm}
                  {...{
                    handleFormSubmit: props.onPrePlanFormSubmit,
                    handleFormChange: props.onPrePlanFormChange,
                    onCancel: props.onCancelPrePlan,
                    formLocation: props.locationDataForm
                  }} />
              ) : (
                <div>
                  <Box>
                    <strong>To continue:</strong> Trace the edges of the location on the map using the drawing tool.
                  </Box>
                  <Flex paddingTop="1rem" alignItems="center" justifyContent="center">
                    {
                      !props.reDrawPolygon && props.location &&
                      <OutlineButton width="100%" onClick={props.onRePlanStart}>Redraw</OutlineButton>
                    }
                  </Flex>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Box>
  );

};

const LocationInformation = ({ location, ...props }) => {
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  // grid or stack
  const layout = 'grid';

  const GridView = (props) => (
    <Grid templateColumns="6.5rem 1fr" gap={'1rem'} rowGap="0.25rem">
      {props.children}
    </Grid>
  );

  const StackView = (props) => (
    <Stack spacing="0">
      {props.children}
    </Stack>
  );
  const LayoutView = (props) => (
    layout === 'grid' ? <GridView>{props.children}</GridView> : <StackView>{props.children}</StackView>
  );
  return (
    <Box>
      <Stack spacing="1rem">
        <Flex>
          {location.isHighHazard && (
            <Tag colorScheme="orange" size="md">
              <TagLeftIcon as={FaExclamationCircle} />
              <Box my={1}><TagLabel fontWeight={600}>HIGH HAZARD</TagLabel>
                {location.highHazardDescription && <Text mt={1}>{location.highHazardDescription}</Text>}
              </Box>
            </Tag>
          )}
        </Flex>
        <LayoutView>
          {location.name && (<React.Fragment>
            <Text {...labelStyle}>
              Business Name
            </Text>
            <Text fontWeight="600">
              {location.name ? `${location.name}` : '--'}
            </Text>
          </React.Fragment>)}
          <Text {...labelStyle}>
            Address
          </Text>
          <AddressStack
            {...location.address}
          />
          {location.lotNumber && (<React.Fragment>
            <Text {...labelStyle}>
              Lot Number
            </Text>

            <Text fontWeight="600">{location.lotNumber}</Text>
          </React.Fragment>)}

          {location.customerZone && (<React.Fragment>
            <Text {...labelStyle}>
              District
            </Text>
            <Text fontWeight="600">{location.customerZone}</Text>
          </React.Fragment>)}
        </LayoutView>
        <Divider />
        <StatGroup>
          <Stat>
            <StatLabel>Floors Above</StatLabel>
            <StatNumber>{location.storey ? location.storey.toLocaleString() : '--'}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Floors Below</StatLabel>
            <StatNumber>{location.storeyBelow ? location.storeyBelow.toLocaleString() : '--'}</StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup>
          <Stat>
            <StatLabel>Roof Area</StatLabel>
            <StatNumber>{location.roofArea ? location.roofArea.toLocaleString() : '--'}</StatNumber>
            <StatHelpText>sq/ft</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Required Flow</StatLabel>
            <StatNumber>{location.requiredFlow ? (
              location.requiredFlow.toLocaleString()
            ) : '--'
            }</StatNumber>
            <StatHelpText>gal/min</StatHelpText>
          </Stat>
        </StatGroup>
        {location.riskAssessmentScore && (<React.Fragment>
          <Divider />
          <StatGroup>
            <Stat>
              <StatLabel>Risk Assessment Score</StatLabel>
              <StatNumber>{location.riskAssessmentScore ? (
                location.riskAssessmentScore
              ) : '--'
              }</StatNumber>
            </Stat>
          </StatGroup>
        </React.Fragment>)}
      </Stack>
    </Box>
  );
};

export default LocationData;
