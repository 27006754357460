import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast
} from '@chakra-ui/react';
import { DataTable } from '../../components/dashboard/DataTable';
import styled from 'styled-components';
import { formatLocationDate, formatDate, isAfterSubDays } from '../../helpers/Date';
import { useDispatch, useSelector } from 'react-redux';
import { FlexWithBackgroundTheme } from '../ThemeContainer';
import { FaDownload } from '@react-icons/all-files/fa/FaDownload';

import { FaRegCalendarCheck } from '@react-icons/all-files/fa/FaRegCalendarCheck';
import { selectAllLocations, fetchAllLocations } from '../../features/locations/locationsSlice';
import {getUIConfig} from '../../features/user/userSlice';
import { CSVLink } from 'react-csv';
import { PreplanExport } from '../../models/Preplan';
import { selectAllAssignments, fetchAllAssignments } from '../../features/assignments/assignmentsSlice';
import { CreateAssignmentModal } from '../../components/assignments/AssignmentModal';

import { FilterTabs } from './FilterTabs';
import { allFields as buildingDataFields } from '../../components/form/BuildingDataFormComponent';
import { getNameOrEmail } from '../../helpers/User';
import {FaChevronDown} from '@react-icons/all-files/fa/FaChevronDown';
import {getHydrantReportCSV, getISOReportCSV} from '../../api/LocationAPI';
import {getLocationAssignments} from '../../components/assignments/AssignmentUtil';
import { allColumns } from './PrePlanTableColumns';

export const PreplansContainer = (/*props*/) => {
  return (
    <FlexWithBackgroundTheme w="100%" height={'calc(100vh - 4.25rem)'} overflow="hidden">
      <PreplanDataContainer />
    </FlexWithBackgroundTheme>
  );
};

const completionCalc = (parsedData) => {
  const breakdown = {
    address: 0,
    footprint: 0,
    building: 0,
    hydrants: 0,
    annotations: 0
  };
  let completion = 0;
  if (parsedData.address && parsedData.address.address1) {
    completion += 5;
    breakdown.address = 5;
  }
  if (!parsedData.latLon) {
    completion += 10;
    breakdown.footprint += 10;
  }
  if (parsedData.building) {
    const buildingFields = Object.keys(parsedData.building).filter(key => buildingDataFields.find(b => b.id === key));

    if (!buildingFields.length || buildingFields.length === 0) {
      breakdown.building = 0;
    } else if (buildingFields.length <= 2) {
      completion += 5;
      breakdown.building += 5;
    } else if (buildingFields.length <= 4) {
      completion += 10;
      breakdown.building += 10;
    } else if (buildingFields.length <= 6) {
      completion += 15;
      breakdown.building += 15;
    } else if (buildingFields.length <= 8) {
      completion += 20;
      breakdown.building += 20;
    } else if (buildingFields.length >= 9) {
      completion += 25;
      breakdown.building += 25;
    }
  } else {
    console.warn('Building data undefined: ', parsedData.building); // eslint-disable-line no-console
  }
  if (Array.isArray(parsedData.hydrants) && parsedData.hydrants.length > 0) {
    completion += 10;
    breakdown.hydrants += 10;
  }
  const imgL = parsedData.imageAnnotations;
  if (imgL && imgL > 5) {
    completion += 50;
    breakdown.annotations += 50;
  }
  if (imgL && imgL <= 5) {
    completion += imgL * 10;
    breakdown.annotations += imgL * 10;
  }

  completion = completion > 100 ? 100 : completion;

  return {
    completion: completion,
    breakdown: breakdown
  };
};

const getVisibleDataHeaders = (visibleHeaderIds) => {
  const requiredFields = [
    { label: 'FlowMSP Building ID', key: 'id' },
    { label: 'Business Name', key: 'businessName' },
    { label: 'Street Address 1', key: 'address1' },
    { label: 'Street Address 2', key: 'address2' },
    { label: 'City', key: 'city' },
    { label: 'State', key: 'state' },
    { label: 'Zip', key: 'zip' },
    { label: 'Lot Number', key: 'lotNumber' },
    { label: 'District', key: 'customerZone' }
  ];

  const optionalFields = [
    { label: 'Floors Above', key: 'storey' },
    { label: 'Floors Below', key: 'storeyBelow' },
    { label: 'Roof Area', key: 'roofArea' },
    { label: 'Hydrants', key: 'hydrants' },
    { label: 'Images', key: 'imageLength' },
    { label: 'ImageAnnotations', key: 'imageAnnotations' },
    { label: 'Building Data Completion', key: 'completion' },
    { label: 'Floor Plan', key: 'floorPlan' },
    { label: 'High Hazard', key: 'isHighHazard' },
    { label: 'Risk Assessment Score', key: 'riskAssessmentScore' },
    { label: 'Review Status', key: 'reviewStatus' },
    { label: 'Created On', key: 'originalPrePlan' },
    { label: 'Last Reviewed On', key: 'lastReviewedOn' },
    { label: 'Last Reviewed By', key: 'lastReviewedBy' },
    { label: 'Assigned By', key: 'assignedBy' },
    { label: 'Assigned To', key: 'assignedTo' },
    { label: 'Assignment Status', key: 'assignmentStatus' },
    { label: 'Assignment Due On', key: 'assignmentDueOn' },
    ...buildingDataFields.map(b => ({
      label: b.label,
      key: `building.${b.id}`
    }))
  ];

  return [
    ...requiredFields,
    ...optionalFields.filter(h => visibleHeaderIds.indexOf(h.key) > -1)
  ];
};


const measureReviewStatus = (lastReviewedOn) => {
  const parsedDate = formatLocationDate(lastReviewedOn);

  if (isAfterSubDays(parsedDate, 270)) {
    return 'Updated';
  } else if (isAfterSubDays(parsedDate, 365)) {
    return 'Due';
  }
  return 'Urgent';
};

const mapLocationData = (data) => {
  return data.map((item) => {
    return {
      id: item.id,
      businessName: item.name,
      address: item.address,
      lotNumber: item.lotNumber,
      customerZone: item.customerZone,
      storey: item.storey,
      storeyBelow: item.storeyBelow,
      roofArea: item.roofArea,
      hydrants: Array.isArray(item.hydrants) ? item.hydrants.length : 0,
      imageLength: item.imageLength ? item.imageLength : 0,
      imageAnnotations: item.imageAnnotations ? item.imageAnnotations : 0,
      completion: completionCalc(item),
      floorPlan: item.imageTags && item.imageTags.indexOf('Floor Plan') > -1,
      isHighHazard: Boolean(item.isHighHazard),
      riskAssessmentScore: item.riskAssessmentScore,
      reviewStatus: item.building && measureReviewStatus(item.building.lastReviewedOn ? item.building.lastReviewedOn : ''),
      originalPrePlan: item.building && item.building.originalPrePlan ? item.building.originalPrePlan : '',
      lastReviewedOn: item.building && item.building.lastReviewedOn ? item.building.lastReviewedOn : '',
      lastReviewedBy: item.building && item.building.lastReviewedBy ? item.building.lastReviewedBy : '',
      assignedBy: item.assignedBy,
      assignedTo: item.assignedTo,
      assignmentStatus: item.assignmentStatus,
      assignmentDueOn: item.assignmentDueOn,
      assignmentNotes: item.assignmentNotes,
      rawAssignmentData: item.rawAssignmentData,
      building: item.building ? item.building : {}
    };
  });
};

// Filters

// const isHighHazard = (loc) => loc.isHighHazard;

const PreplanDataContainer = (/*props*/) => {

  const { /*entities,*/ loading, /*error*/ lastFetch: locationsLastFetch } = useSelector(state => state.locations);
  const { lastFetch: assignmentsLastFetch } = useSelector(state => state.assignments);
  // const { entities: assignmentEntities, loading: assignmentLoading, error: assignmentError } = useSelector(state => state.assignments);

  const dispatch = useDispatch();

  const locations = useSelector(selectAllLocations);
  const assignments = useSelector(selectAllAssignments);
  const customer = useSelector(state => state.customer);
  const user = useSelector(state => state.user);
  const uiConfig = getUIConfig(user);

  // Mapped data
  const [tableData, setTableData] = useState(null);
  // array of row's location.id
  const [selectedRows, setSelectedRows] = useState([]);
  const [visibleHeaderIds, setVisibleHeaderIds] = useState([]);

  useEffect(() => {
    if (locations.length === 0) {
      dispatch(fetchAllLocations());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, );

  useEffect(() => {
    if (assignments.length === 0) {
      dispatch(fetchAllAssignments());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, );
  useEffect(() => {
    if (!locationsLastFetch || !assignmentsLastFetch) return;

    if (locations.length > 0 && assignments.length > 0) {
      const mappedData = mapAssignmentData(locations.filter(l => l.isMine), assignments);
      setTableData(mapLocationData(mappedData));
    } else {
      setTableData(mapLocationData(locations.filter(l => l.isMine)));
    }
  }, [locations, assignments, locationsLastFetch, assignmentsLastFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const rowsSelected = (rows) => {
    setSelectedRows(rows.map(r => r.original.id));
  };

  const mapAssignmentData = (mLocations, mAssignments) => {
    return mLocations.map(loc => {

      // Array.from(new Set(Object.values(state.assignments.entities).map(e => e.locationId))).forEach(locationId => {
      //   const assignments = Object.values(state.assignments.entities).filter(assignment => assignment.locationId === locationId);
      //   const latest = assignments.sort((a, b) => parseISO(b.modifiedOn).getTime() - parseISO(a.modifiedOn).getTime());
      //
      //   console.log(locationId, assignments, latest[0], assignments.map(e => e.modifiedOn));
      // })

      const locAssignments = getLocationAssignments(mAssignments, loc.id);
      if (locAssignments.length > 0) {
        const latestAssignment = locAssignments[0];
        const assignedBy = customer.users.find(u => u.id === latestAssignment.assignedBy);
        const assignedTo = customer.users.find(u => u.id === latestAssignment.assignedTo);
        return {
          ...loc,
          assignedBy: assignedBy ? getNameOrEmail(assignedBy) : '',
          assignedTo: assignedTo ? getNameOrEmail(assignedTo) : '',
          assignmentStatus: latestAssignment.assignmentStatus,
          assignmentDueOn: latestAssignment.assignmentDueOn,
          rawAssignmentData: latestAssignment,
          assignmentNotes: latestAssignment.notes
        };
      }
      return {
        ...loc
      };
    });
  };

  const activeFilters = uiConfig.dashboardFilterValues;
  const hiddenColumns = Object.keys(uiConfig.dashboardColumnsVisibility).filter(k => !uiConfig.dashboardColumnsVisibility[k]);

  return (
    <Flex width={'100%'} height="100%" direction={'column'} paddingBottom="1.5rem" paddingX={'1.5rem'} overflow="auto">
      <Heading as="h2" size="lg" mt="1rem" mb="0.5rem">Preplan Dashboard</Heading>
      <Divider />
      <Box p="1rem" marginTop="2rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="sm">
        <Flex direction={'row'} alignItems="center" justifyContent={'space-between'}>
          <Heading as="h4" size="lg" ml={3}>Locations & Assignments</Heading>
          <Menu placement="bottom-end" closeOnSelect={false}>
            {({ onClose }) => (
                <React.Fragment>
                  <MenuButton as={Button} variant="outline" leftIcon={<FaDownload />} rightIcon={<FaChevronDown />}>Download</MenuButton>
                  <MenuList>
                    <MenuItem as={'div'} style={{padding: 0}}>
                      <DownloadButton
                          menuItem
                          variant="ghost"
                          visibleHeaderIds={visibleHeaderIds}
                          tableData={tableData}
                          onComplete={onClose}
                      >
                        Preplan Report (CSV)
                      </DownloadButton>
                    </MenuItem>
                    <MenuItem as={'div'} style={{padding: 0}}>
                      <CSVDownloadButton onComplete={onClose} execute={() => getISOReportCSV(customer.slug)} filename="iso_report.csv">ISO Report (CSV)</CSVDownloadButton>
                    </MenuItem>
                    <MenuItem as={'div'} style={{padding: 0}}>
                      <CSVDownloadButton onComplete={onClose} execute={() => getHydrantReportCSV(customer.slug)} filename="hydrant_report.csv">Hydrant Report (CSV)</CSVDownloadButton>
                    </MenuItem>
                  </MenuList>
                </React.Fragment>
            )}
          </Menu>
          {/*<DownloadButton visibleHeaderIds={visibleHeaderIds} tableData={tableData} />*/}
        </Flex>
        <Divider my="0.5rem" />
        <TableStyles>
          {tableData && <DataTable
            actionComponent={<AssignmentButton selectedRows={selectedRows} />}
            rowsSelected={rowsSelected}
            selectable
            globalSearch
            loading={loading === 'pending'}
            columns={allColumns}
            hiddenColumns={hiddenColumns}
            data={tableData}
            Filters={FilterTabs}
            activeFilters={activeFilters}
            setVisibleHeaderIds={setVisibleHeaderIds}
            chakraProps={{
              variant: 'striped',
              size: 'sm'
            }}
          />}
        </TableStyles>
      </Box>
    </Flex>
  );

};


const AssignmentButton = (props) => {
  const user = useSelector(state => state.user);
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const assignments = useSelector(selectAllAssignments);
  const [canEdit, setCanEdit] = useState(false);
  const onClick = () => {
    setAssignmentModalOpen(true);
  };
  const [buttonText, setButtonText] = useState('Create Assignment');

  useEffect(() => {
    setCanEdit(false);
    if (user.role === 'ADMIN' || user.role === 'PLANNER') {
      setCanEdit(true);
    }
    // if (user.role === 'PLANNER') {
    //   // const myAssignments = props.selectedRows.filter(f => f.assignment.assignedTo).filter(a => a !== user.id).length < -1;
    //   const notMy = [];
    //   for (let currentAssignment, i = 0; i < props.selectedRows.length; i++) {
    //     currentAssignment = getLatestLocationAssignments(assignments, props.selectedRows[i]);
    //     if (currentAssignment) {
    //       if (currentAssignment.assignedTo !== user.id) {
    //         notMy.push(props.selectedRows[i]);
    //         break;
    //       }
    //     }
    //   }
    //   setCanEdit(notMy.length < 1);
    // }
  }, [user, props.selectedRows]);

  useEffect(() => {
    if (props.selectedRows.length > 0) {
      setButtonText(`Assign ${props.selectedRows.length} Locations`);
      for (let i = 0; i < props.selectedRows.length; i++) {
        if (assignments.find(assignment => assignment.locationId === props.selectedRows[i])) {
          setButtonText(`Update ${props.selectedRows.length} Assignments`);
          break;
        }
      }
    } else {
      setButtonText('Create Assignment');
    }
  }, [props.selectedRows]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [props.selectedRows, assignments]);

  return (
    <React.Fragment>
      <Button style={{minWidth: 'unset', marginLeft: '1em'}} isDisabled={(props.selectedRows.length < 1) || !canEdit} variant="outline" color="green" leftIcon={<FaRegCalendarCheck />} onClick={onClick}>
        {buttonText}
      </Button>
      <CreateAssignmentModal showModal={assignmentModalOpen} toggleModal={() => {
        setAssignmentModalOpen(false);
      }} locationIds={props.selectedRows} />
    </React.Fragment>
  );
};

const DownloadButton = (props) => {
  const customer = useSelector(state => state.customer);
  const [downloadData, setDownloadData] = useState([]);
  useEffect(() => {
    if (!props.tableData) return;

    try {
      setDownloadData(mapTableData(props.tableData));
    } catch (e) {
      console.error('Error mapping table data to download data: ', e); // eslint-disable-line no-console
      setDownloadData([]);
    }
  }, [props.tableData]);

  const mapTableData = (locs) => {
    return locs.map((item) => PreplanExport.parse({
      ...item,
      address1: item.address.address1,
      address2: item.address.address2,
      city: item.address.city,
      state: item.address.state,
      zip: item.address.zip,
      completion: item.completion.completion,
    }));
  };

  if (props.menuItem) {
    return (
        <Button variant="ghost" style={{
          fontWeight: 'inherit',
          color: 'inherit',
          flexGrow: 1,
          justifyContent: 'start',
          borderRadius: 0
        }} onClick={props.onComplete} as={CSVLink} headers={getVisibleDataHeaders(props.visibleHeaderIds)} filename={`${customer.name} Preplan Report ${formatDate(new Date())}.csv`} data={downloadData}>
          {props.children || 'Download CSV'}
        </Button>
    );
  }

  return (
      <Button onClick={props.onComplete} variant="outline" as={CSVLink} headers={getVisibleDataHeaders(props.visibleHeaderIds)} filename={`${customer.name} Preplan Report ${formatDate(new Date())}.csv`} data={downloadData} leftIcon={<FaDownload />}>
        {props.children || 'Download CSV'}
      </Button>
  );

};

const CSVDownloadButton = ({ children, execute, onComplete, filename }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const toast = useToast();

  return (
      <Button variant="ghost"
              style={{
                fontWeight: 'inherit',
                color: 'inherit',
                flexGrow: 1,
                justifyContent: 'start',
                borderRadius: 0
              }}
              as={'a'}
              isLoading={isLoading}
              loadingText="Downloading"
              onClick={(e) => {
                setIsLoading(true);
                execute().then(res => {

                  if (!res || !res.data) {
                    toast({
                      title: 'Error',
                      position: 'top',
                      description: 'There was a problem downloading the file, please try again.',
                      status: 'error',
                      duration: 5000,
                      isClosable: true
                    });
                    setIsLoading(false);
                    onComplete();
                    return;
                  }

                  const blob = new window.Blob([res.data], {
                    type: 'text/csv'
                  });
                  const file = URL.createObjectURL(blob);

                  const a = document.createElement('a');
                  a.href = file;
                  a.download = filename;
                  e.target.parentNode.appendChild(a);
                  a.click();
                  a.remove();
                  onComplete();
                  setIsLoading(false);
                }, err => {
                  console.error('Failed to download file', err); // eslint-disable-line no-console
                  toast({
                    title: 'Error',
                    position: 'top',
                    description: 'There was a problem downloading the file, please try again.',
                    status: 'error',
                    duration: 2500,
                    isClosable: true
                  });
                });
                
              }}
      >
        {children || 'Download CSV'}
      </Button>
  );

};


//@todo move...
const TableStyles = styled.div`
  .hydrants,
  .images,
  .completion,
  .floorPlan,
  .highHazard,
  .district,
  .reviewStatus {
    font-weight: 600;
  }
  .address .name,
  .name-date .name {
    font-weight: 600;
  }
  td, th {
    padding: 0.5rem;
  }
  th {
    align-items: end;
    overflow: hidden;
  }
  thead {
    border-bottom: 1px solid var(--chakra-colors-gray-600);
  }
`;
